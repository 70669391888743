import React from 'react'
import SectionTitle from 'components/common/SectionTitle'
import Layout from 'components/Layout'

import TEXT from 'locales/imprint.json'

const ImprintPage: React.FC = () => {
  return (
    <Layout title="Imprint">
      <main className="max-w-md p-8 sm:p-12 grid grid-flow-row grid-cols-1 gap-6">
        <SectionTitle position="left">{TEXT.title.toUpperCase()}</SectionTitle>
        <div
          className="text-body-2"
          dangerouslySetInnerHTML={{ __html: TEXT.imprintText }}
        />
      </main>
    </Layout>
  )
}

export default ImprintPage

import React from 'react'

interface SectionTitleProps {
  className?: string
  position?: 'left' | 'center'
  id?: string
  isMainTitle?: boolean
  textClassName?: string
}

export const Separator = () => (
  <hr className="border-t-0 border-b-4 border-red my-4 w-16" />
)

const SectionTitle: React.FC<SectionTitleProps> = ({
  className,
  children,
  id,
  isMainTitle,
  position,
  textClassName,
}) => {
  const rootClassName = [
    'flex flex-col',
    position === 'left' ? 'items-start' : 'items-center',
    className,
  ].join(' ')

  if (isMainTitle) {
    return (
      <div className={rootClassName} id={id}>
        <h1 className={['text-primary', textClassName].join(' ')}>
          {children}
        </h1>
        <Separator />
      </div>
    )
  }

  return (
    <div className={rootClassName} id={id}>
      <h2 className={['font-bold text-xl', textClassName].join(' ')}>
        {children}
      </h2>
      <Separator />
    </div>
  )
}

export default SectionTitle
